@media print {
  #print-container.rendered .btn-print-util {
    display: none;
  }
}

body {
  counter-reset: page;
  counter-reset: pageTotal;
}

section::after {
  counter-increment: pageTotal;
}

@media screen {
  .print-container {
    display: none;
    font-size: 5px;
  }
}

.fontSize {
  font-size: 12px;
}

.fontSizeComment {
  font-size: 11px;
}

@page {
  size: 8.5in 11in;
  /* margin: 20mm 10mm; */
  margin: 3mm 8mm 10mm 8mm;
  overflow: scrolls;
  background-color: white;
  /* font-size: 8pt !important; */
  font-family: Arial;

  @top-right {
    /* content: "Page " counter(page) " of " counter(pageTotal); */
  }

  @top-left {
    content: element(pageheader);
  }

  @bottom-left {
    content: element(pagefooter);
  }
}

/* .section {
  break-before: right;
  break-after: always;
} */

#total-cost-table,
#table-handling tbody,
#margin-table {
  page-break-inside: avoid;
}

.page-counter::after {
  counter-increment: page;
  /* content: "Page " counter(page) " of " counter(pageTotal); */
}

.page-header {
  position: running(pageheader);
}

.page-footer {
  position: running(pagefooter);
}

.comment-box textarea {
  margin-top: -2em;
  margin-left: -1em;
}

h1 {
  width: 100%;
  text-align: center;
}

.material-list-table tbody tr td {
  page-break-inside: avoid;
}

.table-normal th {
  border: 1px solid black;
}

.table-normal td {
  border: 1px solid black;
}

th span.rotate {
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
}

.table-product-description th {
  border-right: 2px solid black;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
}

.table-product-description td {
  border-right: 2px solid black;
  border-left: 2px solid black;
  font-size: 10pt;
}

.table-product-description tbody tr td {
  page-break-inside: avoid;
  /* white-space: nowrap; */
}

.table-product-description td.border-left-white {
  border-left: 2px solid white !important;
}

.table-product-description td.border-bottom-black {
  border-bottom: 2px solid black !important;
}

.table-product-description td.border-bottom-white {
  border-bottom: 2px solid white !important;
}

.table-product-description td.border-right-white {
  border-right: 2px solid white !important;
}

.table-product-description td.border-top-black {
  border-top: 2px solid black !important;
}