@media screen {
  .print-container {
    display: none;
  }
}

#recipent-header {
  display: flex;
  margin-bottom: 1em;
  justify-content: space-between;
}

#recipent-list {
  display: flex;
  /* flex-grow: 1; */
}

#recipent-list>p {
  margin: 0;
}

#recipent-list>span {
  margin-right: 1em;
}

.wo-print table.with-border {
  border-collapse: collapse;
}

.wo-print table.with-border tr>* {
  border: 1px solid black;
}

#recipent-signatures table {
  border-collapse: collapse;
}

#recipent-signatures table tr>* {
  padding: 0.5em;
  /* width: 33.333%; */
  text-align: center;
  word-break: break-word;
}

#recipent-signatures table tbody tr>* {
  height: 5em;
  vertical-align: bottom;
}

#item-table {
  width: 100%;
  margin-top: 1em;
}

#item-table tr>* {
  padding: 0.5em;
  text-align: center;
  word-break: break-word;
}

#instruction-detail {
  white-space: pre-line;
}

#delivery-checked tr>* {
  padding: 0.5em;
}

#delivery-checked {
  width: 14em;
}

#bottom-approval {
  display: flex;
}

#approvals {
  flex-grow: 1;
}

.signature-space {
  height: 1rem;
  width: 10rem;
}

.company-text-info {
  max-width: 120mm;
  margin-left: 5mm;
}

#wo-notice-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#wo-notice-container table tr>* {
  padding: 0.4em;
  padding-right: 0;
  vertical-align: baseline;
}

#wo-notice-container table tr>*:first-child {
  padding-left: 0;
}

#wo-numbers {
  padding-left: 2em;
  min-width: 90mm;
}

@page {
  size: 8.5in 11in;
  margin: 10mm 10mm 20mm 10mm;
  background-color: white;
  font-family: Arial;

  @footnote {
    margin: 0.6em 0 0 0;
    padding: 0.3em 0 0 0;
    max-height: 10em;
  }

  @top-left {
    content: element(pageheader);
  }

  @top-right {
    content: element(wotitle);
  }

  @bottom-left {
    content: element(pagefooter);
  }
}

.section {
  break-before: right;
  break-after: always;
}

#total-cost-table,
#margin-table {
  page-break-inside: avoid;
}

.page-header {
  position: running(pageheader);
}

.page-footer {
  position: running(pagefooter);
}

h1 {
  width: 100%;
  text-align: center;
}

.wo-title-container {
  position: running(wotitle);
}

.wo-title {
  position: absolute;
  width: 60mm;
  right: 10mm;
  top: 5mm;
}

.wo-title p {
  margin: 0;
}

/* .wo-title .counter::after { */
/* content: "Page " counter(page) " of " counter(pages); */
/* } */

.wo-title u {
  font-size: 16px;
  margin-top: 2;
  margin-bottom: 2;
}

#bottom-approval {
  page-break-inside: avoid;
}

#delivery-checked tbody tr td {
  page-break-inside: avoid;
}