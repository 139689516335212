@media screen {
  .print-container {
    overflow: hidden;
    height: 0;
  }
}

@media print {
  @page {
    size: A4 landscape;
  }

  table.table {
    width: 100%;
    border-collapse: collapse;
  }

  table.table-bordered th,
  table.table-bordered td {
    border: 1px solid black;
  }

  table.table tbody tr td {
    font-size: 7pt;
    padding: 4px;
  }

  table.table tbody tr {
    page-break: avoid;
  }
}
